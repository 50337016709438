<template>
	<div>
		<el-table border v-loading="loading" :data="dataList">
			<el-table-column
				label="生成日期范围"
				prop="downloadDateRange"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="报表类型"
				prop="reportType"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">{{
					dicts.REPORT_TYPE[scope.row.reportType]
				}}</template>
			</el-table-column>
			<el-table-column
				label="上游通道"
				prop="channelNo"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.channelNo | channelFM }}
				</template>
			</el-table-column>
			<el-table-column
				label="创建时间"
				prop="createTime"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="操作"
				prop="withholdingNo"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					<template v-if="scope.row.status == 'SUCCESS'">
						<el-button
							size="mini"
							type="text"
							@click="handleDown(scope.row)"
							>下载</el-button
						>
						<el-button
							size="mini"
							type="text"
							@click="handleAgain(scope.row)"
							>重新生成</el-button
						>
					</template>
					<template v-else>
						{{ dictTranslate(scope.row.status, statusList) }}
					</template>
				</template>
			</el-table-column>
		</el-table>

		<Pagination
			v-show="total > 0"
			:total="total"
			:page.sync="pageNo"
			:limit.sync="pageSize"
			@pagination="getList"
		/>
	</div>
</template>

<script>
import { ProfitManage } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		queryParams: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			pageNo: 1,
			pageSize: 10,
			loading: false,
			total: 0,
			dataList: [], // 表格数据
			selectRow: {},
			showCustomForm: false,
			isAdd: false,
			// downloadTypeList: { DAY: "按日", MONTY: "按月" },
			reportType: {
				PROFIT_REPORT: "分润报表",
				ACTIVITY_REPORT: "活动返现报表"
			},
			statusList: {
				INIT: "初始化",
				DOWNLOADING: "生成中，请稍后",
				SUCCESS: "下载成功",
				FAILED: "下载失败"
			}
		};
	},
	mounted() {},
	watch: {
		queryParams: function() {
			this.pageNo = 1;
			this.getList();
		}
	},
	methods: {
		async getList() {
			this.loading = true;
			let result = await ProfitManage.execlDownloadInfo.list(
				this.pageNo,
				this.pageSize,
				this.queryParams
			);
			this.loading = false;
			this.total = result.count || 0;
			this.dataList = result.data || [];
		},
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.getList();
		},
		async handleUpdate(row) {
			this.showCustomForm = true;
			this.selectRow = row;
			this.isAdd = false;
			this.getList();
		},
		// 下载
		handleDown(row) {
			// window.location.href = row.fileUrl;
			window.open(row.fileUrl);
		},
		// 重新生成事件
		async handleAgain(row) {
			let result = await ProfitManage.execlDownloadInfo.reDownload(row);
			console.log("reDownload----->160", result);
			if (result.success) {
				// this.$message.success("操作成功");
				this.$message.success(result.data);
				this.getList();
			}
		}
	}
};
</script>

<style scoped></style>
